import * as React from 'react'
import { BlockTitle, Container } from 'components/common/styles'
import styled from '@emotion/styled'
import { between } from 'polished'

import AnySizeJPG from 'assets/images/merchants/any_size.jpg'
import Button, { ButtonSize } from '../../../common/button'

type Props = {};

const AdaptiveContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  
  margin-top: 120px;
  
  @media screen and (max-width: 768px) {
    padding: 17px;
    flex-direction: column-reverse;
    
    margin-top: 60px;
  }
`

type ImageProps = {
  src: string
}
const Image = styled.div<ImageProps>`
  background-size: cover;
  background-position: center center;
  background-image: url("${props => props.src}");
  border-radius: 40px;
  padding-bottom: 520px; //78
  
  padding-left: 666px;
  
  @media screen and (max-width: 1600px) {
    padding-left: ${between('300px', '666px', '768px', '1600px')};
    padding-bottom: ${between('234px', '520px', '768px', '1600px')};
  }
  
   @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-bottom: 78%;
  }
`

const Info = styled.div`
  padding-left: 10%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 24px;
  }
  
  button {
    align-self: flex-start;
    margin-top: 24px;
  }
`
const Title = styled(BlockTitle)`
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const Description = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #666;
`

const PhotoBlock: React.FC<Props> = props => {
  return (
    <AdaptiveContainer>
      <Image src={AnySizeJPG} />
      <Info>
        <Title>All size merchants are welcome</Title>
        <Description>
          Unlike many traditional banks and corporate cards, ComCard welcomes merchants of all shapes and sizes --
          enabling you to start reaping the benefits of our products, and growing and retaining more customers,
          immediately.
        </Description>
        <Button size={ButtonSize.MEDIUM} onClick={() => {
          window.location = '/merchants#signup'
        }}>Join Waitlist</Button>
      </Info>
    </AdaptiveContainer>
  )
}

export default PhotoBlock
