import * as React from 'react'
import { BlockTitle, Container } from 'components/common/styles'
import FeaturesList from 'components/features-list'
import styled from '@emotion/styled'

import ShieldSvg from 'assets/images/icons/shield.svg'
import BoardSvg from 'assets/images/icons/board_with_check.svg'
import CACSvg from 'assets/images/icons/cac.svg'

type Props = {};

const AdaptiveContainer = styled(Container)`
  margin-top: 120px;
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
    margin-top: 60px;
  }
`

const Title = styled(BlockTitle)`
  margin-bottom: 45px;
`

const Features: React.FC<Props> = props => {
  return (
    <AdaptiveContainer>
      <Title>Joining ComCard’s merchant network is a no-brainer</Title>
      <FeaturesList items={[
        {
          icon: (<img src={ShieldSvg} />),
          title: 'No fees to join',
          description: 'Start growing your customer base using our proprietary loyalty system -- immediately and at no cost.',
        },
        {
          icon: (<img src={BoardSvg} />),
          title: 'Precise targeting and reporting',
          description: 'Reach the right customers at the right times using our advanced software tools.',
        },
        {
          icon: (<img src={CACSvg} />),
          title: 'Reduce acquisition costs',
          description: 'Our advanced loyalty system helps you measure online and offline retention, and incentivize customers to return.',
        },
      ]} />
    </AdaptiveContainer>
  )
}

export default Features
