import * as React from 'react'
import styled from '@emotion/styled'
import { BlockTitle, Container } from 'components/common/styles'
import ComparisonTable from 'components/comparison-table'

type Props = {};

const LocalContainer = styled(Container)`
  margin-top: 120px;
  margin-bottom: 120px;
`

const Title = styled(BlockTitle)`
  align-self: center;
  margin-bottom: 45px;
`

const labels = [
  'Cashback credits',
  'Setup costs',
  'Time to market',
  'Extended customer credit',
  'Consumer analytics',
  'Any size merchants',
  'Increases retention',
  'No loyalty card maintenance',
  'Customizable incentives',
]
const data = {
  'ComCard': [
    'Yes',
    'Zero',
    'Minutes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
  ],
  'Other Loyalty Cards': [
    'Yes',
    'Medinum',
    'Days',
    'No',
    'Yes',
    'Yes',
    'Yes',
    'No',
    'Yes',
  ],
  'Banks': [
    'Yes',
    'High',
    'Weeks',
    'Yes',
    'No',
    'No',
    'No',
    'N/A',
    'No',
  ],
}

const Comparison: React.FC<Props> = props => {
  return (
    <LocalContainer>
      <Title>We take loyalty seriously</Title>
      <ComparisonTable labels={labels} data={data} />
    </LocalContainer>
  )
}

export default Comparison
