import React from 'react'
import Layout from 'layouts/default'
import Hero from 'components/hero'
import Steps from 'components/pages/merchants/steps'
import PhotoBlock from 'components/pages/merchants/photo-block'
import Features from 'components/pages/merchants/features'
import Functionality from 'components/pages/merchants/functionality'
import Comparison from 'components/pages/merchants/comparison'
import ContactBlock, { Visibility } from 'components/contact-block'
import Form from 'components/pages/merchants/form'

import HeroJPG from 'assets/images/merchants/hero.jpg'

const MerchantsPage: React.FC = props => {
  React.useEffect(() => {
    window.analytics.page('For Merchants')
  }, [])
  return (
    <Layout title='For Merchants | ComCard'>
      <Hero title="Attract new customers and keep them coming back"
            subTitle="Join our growing merchant network to drive business and build loyalty with ComCard’s advanced software and promotion tools."
            image={HeroJPG} />

      <PhotoBlock />
      <Features />
      <Steps />
      <Functionality />
      <Comparison />
      <ContactBlock visibility={Visibility.MERCHANT} />
      <Form />
    </Layout>
  )
}

export default MerchantsPage
