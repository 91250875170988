import * as React from 'react'
import { BlockTitle, Container } from 'components/common/styles'
import Button, { ButtonSize } from 'components/common/button'
import styled from '@emotion/styled'

import StepsSvg from 'assets/images/merchants/steps.svg'
import { between } from 'polished'

type Props = {};

const AdaptiveContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  
  margin-top: 60px;
  
  @media screen and (max-width: 768px) {
    padding: 17px;
    flex-direction: column-reverse;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 40%;
  
  @media screen and (max-width: 1300px) {
    width: auto;
    flex: 1;
  }
  
  button {
    align-self: flex-start;
  }
  
  @media screen and (max-width: 768px) {
    button {
      align-self: center;
    }
  }
`
const Title = styled(BlockTitle)`
`
const SubTitle = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #666668;
  
  margin-top: 25px;
  margin-bottom: 40px;
`

const GraphContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 100px;
  
  @media screen and (max-width: 1300px) {
    flex: 0;
    font-size: ${between('60px', '100px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    font-size: calc((85vw - 34px) / 5.25);
  }
`

const Graph = styled.div`
  position: relative;
  display: inline-block;
  width: 5.25em;
  height: 4.81em;
  
  img {
    width: 100%;
    height: 100%;
  }
`

const GraphItems = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  top: 50%;
  transform: translateY(-50%);
  padding-left: .25em;
  width: 100%;
`

const GraphItem = styled.div`
  background: #F9F8F7;
  box-shadow: 0 .05em .14em rgba(14, 61, 57, 0.2);
  border-radius: .4em;
  padding: .24em;
  
  display: flex;
  flex-direction: row;
  
  width: 70%;
  
  &:nth-of-type(2) {
    width: 85%;
  }
  
  &:not(:last-of-type) {
    margin-bottom: .24em;
  }
`

const Number = styled.div`
  width: 2.27em;
  height: 2.27em;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E4F1EF;
  border-radius: .4em;
  
  color: #00A095;
  font-size: .22em;
  font-weight: 600;
`

const Text = styled.div`
  margin-left: 1em;
  font-size: .16em;
  line-height: 1.5;
  color: #666;
`

const Steps: React.FC<Props> = props => {
  return (
    <AdaptiveContainer>
      <Info>
        <Title>Simple as<br />1, 2, 3</Title>
        <SubTitle>
          Integrate with ComCard within minutes and start getting new clients transacting immediately.
        </SubTitle>
        <Button size={ButtonSize.MEDIUM} onClick={() => {
          window.location = '/merchants#signup'
        }}>Start with ComCard</Button>
      </Info>
      <GraphContainer>
        <Graph>
          <img src={StepsSvg} />
          <GraphItems>
            <GraphItem>
              <Number>1</Number>
              <Text>Sign up online with our user-friendly software</Text>
            </GraphItem>
            <GraphItem>
              <Number>2</Number>
              <Text>Create your profile and launch promotional deals to our users</Text>
            </GraphItem>
            <GraphItem>
              <Number>3</Number>
              <Text>Activate your terminal with a one time virtual credit card</Text>
            </GraphItem>
          </GraphItems>
        </Graph>
      </GraphContainer>
    </AdaptiveContainer>
  )
}

export default Steps
