import * as React from 'react'
import { default as axios } from 'axios'
import { BlockTitle, Container } from 'components/common/styles'
import SmartForm from 'components/forms/smart-form'
import SmartInputField from 'components/forms/smart-input-field'
import { ValidationType } from 'components/forms/validations'
import styled from '@emotion/styled'
import SmartNumberInputField from 'components/forms/smart-number-input-field'
import Button, { ButtonSize } from 'components/common/button'
import SentSVG from 'assets/images/sent.svg'
import SmartCheckboxField from '../../../forms/smart-checkbox-field'
import { Link } from 'gatsby'

type Props = {};

const LocalContainer = styled(Container)`
  padding: 120px 0 100px 0;
  max-width: 650px;
  
  @media screen and (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const Title = styled(BlockTitle)`
`

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #646363;
  margin-top: 15px;
  margin-bottom: 30px;
`

const Input = styled(SmartInputField)`
  margin-bottom: 32px;
`

const Number = styled(SmartNumberInputField)`
  margin-bottom: 32px;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`

const Sent = styled.div`text-align: center;`
const SentTitle = styled.div`font-size: 34px;line-height: 1;font-weight: bold;color: #000000;margin-top: 60px;`
const SentSubTitle = styled.div`font-size: 14px;line-height: 1.5;color: #646363;margin-top: 25px;`
const Checkbox = styled(SmartCheckboxField)`margin-bottom: 16px;`
const Checkboxes = styled.div`margin-bottom: 16px;`

const Form: React.FC<Props> = props => {
  const [isSending, setSending] = React.useState(false)
  const [isSent, setSent] = React.useState(false)

  return (
    <LocalContainer id="signup">
      <SmartForm onSubmit={(e, valid, fields, form) => {
        e.preventDefault()
        e.stopPropagation()

        if (!valid || isSending) return

        setSending(true)
        axios.post(`${process.env.GATSBY_WEBSITE_API}/waitlist/merchants`, {
          name: fields.name.value,
          companyName: fields.business_name.value,
          zipcode: fields.zipcode.value,
          email: fields.email.value,
          phone: fields.phone.value,
          newsletter: fields.newsletter.value,
        }).then(() => {
          form.resetForm()
          setSending(false)
          setSent(true)
        }).catch(e => {
          setSending(false)
          alert('Error while sending request. Try again later.')
          window.analytics.track("Error while submitting merchant failed", {})
        })
      }}>
        {!isSent && (
          <>
            <Title>Easy merchant sign up</Title>
            <SubTitle>
              We want to work with you, no matter your business size.
            </SubTitle>

            <Input name="business_name" label="Business Name" validations={[{ type: ValidationType.REQUIRED }]} />
            <Input
              name="zipcode"
              label="Zip code"
              validations={[{ type: ValidationType.REQUIRED }]}
            />
            <Input name="name" label="Your Name" validations={[{ type: ValidationType.REQUIRED }]} />
            <Input
              name="email"
              label="Email"
              validations={[{ type: ValidationType.REQUIRED }, { type: ValidationType.EMAIL }]}
            />
            <Number
              name="phone"
              label="Phone Number"
              type="tel"
              format="(###) ###-####"
              mask="_"
              validations={[{ type: ValidationType.REQUIRED }]}
            />

            <Checkboxes>
              <Checkbox name="newsletter" label="Subscribe to ComCard newsletter." defaultValue={true} />
              <Checkbox
                name="privacy"
                label={<>I read and understand ComCard's <Link to="/legal/privacy-policy">Privacy Policy</Link>.</>}
                validations={[{ type: ValidationType.CHECKBOX }]}
              />
            </Checkboxes>

            <Actions>
              <Button isLoading={isSending} size={ButtonSize.MEDIUM}>Contact me</Button>
            </Actions>
          </>
        )}
        {isSent && (
          <Sent>
            <img src={SentSVG} alt="Sent" />
            <SentTitle>Thank you!</SentTitle>
            <SentSubTitle>We received your request and our manager will contact you soon.</SentSubTitle>
          </Sent>
        )}
      </SmartForm>
    </LocalContainer>
  )
}

export default Form
