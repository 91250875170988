import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { BlockTitle, Container } from 'components/common/styles'
import { between } from 'polished'

import CreditJPG from 'assets/images/merchants/credit.jpg'
import LoyaltyCardJPG from 'assets/images/merchants/loyalty_card.jpg'
import RetentionJPG from 'assets/images/merchants/retention.jpg'
import TargetedJPG from 'assets/images/merchants/targeted.jpg'
//---------------------------------------------
import Arrow from 'assets/images/icons/arrow.svg'

type Props = {};

const AdaptiveContainer = styled(Container)`
  margin-top: 120px;
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
    margin-top: 60px;
  }
`

const Title = styled(BlockTitle)`
`

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 48px;
  border-bottom: 1px solid #BDBDBD;
  
  white-space: nowrap;
  // overflow-x: scroll; /* TODO Replace with fancy scroll or donsult with designers */
  //overflow-y: visible;
  
  @media screen and (max-width: 1366px) {
    display: none;
  }
  
  .simplebar-content {
  display: flex;
  }

  .simplebar-vertical {
    display: none; 
   }
   .simplebar-wrapper {
    padding-bottom: 10px;
   }
   
   .simplebar-scrollbar {
    background: red;
    border-radius: 6px;
    background:  rgba(0,0,0, 0.3);

    &::before {
         position: static;
        //top: auto !important;
    }
  }
`

type TabProps = {
  active: boolean
}
const Tab = styled.div<TabProps>`
  padding: 15px 24px;
  position: relative;
  font-size: 20px;
  line-height: 1.6;
  color: ${props => props.active ? '#1D1E21' : '#666'};
  transition: color ease-in-out 100ms;
  font-weight: 500;
  cursor: pointer;
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    transition: background-color ease-in-out 100ms;
    background: ${props => props.active ? '#109F95' : 'transparent'};
    z-index: 1;
  }
  
  @media screen and (max-width: 1400px) {
    font-size: ${between('14px', '20px', '768px', '1400px')};
  }
`
const TabsContent = styled.div``

type ContentProps = {
  active: boolean
}
const Content = styled.div<ContentProps>`
  display: ${props => props.active ? 'flex' : 'none'};
  flex-direction: row;
  justify-content: space-between;
  
  @media screen and (max-width: 1366px) {
    display: flex;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

type ImageProps = {
  src: string
}
const Image = styled.div<ImageProps>`
  flex-shrink: 0;
  align-self: center;
  background-size: cover;
  background-position: center center;
  background-image: url("${props => props.src}");
  border-radius: 20px;
  width: 535px;
  padding-bottom: 360px;
  
  @media screen and (max-width: 1300px) {
    width: ${between('200px', '535px', '768px', '1300px')};
    padding-bottom: ${between('135px', '360px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 67%;
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin-top: 34px;
  }
`
const ContentTitle = styled.h3`
  font-size: 34px;
  line-height: 1.6;
  font-weight: bold;
  color: #000;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const ContentDescription = styled.div`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
`


type DropDownProps = {
  isOpen?: boolean
  active?: boolean
}

const DropDownIcon = styled.img<DropDownProps>`
  transform: ${props => props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
  transition: 0.2s all linear;
`

const DropDownTitle = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
`

const DropDownContainer = styled.div<DropDownProps>`
  margin-top: 32px;

  :first-of-type {
    margin-top: 16px;
  }
  
  cursor: pointer;
  
  @media screen and (min-width: 1367px) {
    display: ${props => props.active ? 'initial' : 'none'};
    cursor: default;
  }
  
`

const DropDownHeader = styled.div`
  display: none;

  @media screen and (max-width: 1366px) {
    display: flex;
    justify-content: space-between;
  }
`

const DropDownContent = styled.div<DropDownProps>`
  visibility: ${props => props.isOpen ? 'auto' : 'hidden'};
  max-height: ${props => props.isOpen ? 'max-content' : '0px'};
  padding-top: ${props => props.isOpen ? '24px' : 0};
  
  @media screen and (min-width: 1367px) {
    max-height: max-content;
    visibility: visible;
    padding-top: 0;
  }

`

const DropDown = ({ active, title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // setIsOpen(active);
  }, [active])

  return (
    <DropDownContainer active={active} onClick={() => setIsOpen(!isOpen)}>
      <DropDownHeader>
        <DropDownTitle>{title}</DropDownTitle>
        <DropDownIcon src={Arrow} isOpen={isOpen} />
      </DropDownHeader>
      <DropDownContent isOpen={isOpen}>
        {children}
      </DropDownContent>
    </DropDownContainer>
  )
}


const Functionality: React.FC<Props> = props => {
  const [activeTab, setActiveTab] = React.useState(1)

  return (
    <AdaptiveContainer>
      <Title>Power your business with ComCard’s advanced tools and tech</Title>
      <Tabs>
        <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Loyalty card</Tab>
        <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>Retention calculator</Tab>
        <Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>Extending credit</Tab>
        <Tab active={activeTab === 4} onClick={() => setActiveTab(4)}>Targeted loyalty reinvented</Tab>
      </Tabs>
      <TabsContent>
        <DropDown active={activeTab === 1} title="Loyalty Card">
          <Content active={activeTab === 1}>
            <Image src={LoyaltyCardJPG} />
            <Info>
              <ContentTitle>Loyalty card</ContentTitle>
              <ContentDescription>
                When your customers use ComCard, they get cashback for purchases that meet your promotional threshold.
                ComCard's built-in loyalty system incentivizes them to keep transacting with you—saving you from new
                acquisition and retention costs.
              </ContentDescription>
            </Info>
          </Content>
        </DropDown>
        <DropDown active={activeTab === 2} title="Retention Calculator">
          <Content active={activeTab === 2}>
            <Image src={RetentionJPG} />
            <Info>
              <ContentTitle>Retention calculator</ContentTitle>
              <ContentDescription>
                With our advanced solutions, you can easily calculate a precise retention rate of your customers both
                online and offline. This allows you to make better decisions on who and how to incentivize for future
                spending at your store.
              </ContentDescription>
            </Info>
          </Content>
        </DropDown>
        <DropDown active={activeTab === 3} title="Extend the credit to your customer">
          <Content active={activeTab === 3}>
            <Image src={CreditJPG} />
            <Info>
              <ContentTitle>Extending credit</ContentTitle>
              <ContentDescription>
                Use ComCard to give your clients a credit limit that they can use towards transactions with you. This
                let's you increase sales and get paid when your customers make a purchase.
              </ContentDescription>
            </Info>
          </Content>
        </DropDown>
        <DropDown active={activeTab === 4} title="Targeted Loyalty Reinvented">
          <Content active={activeTab === 4}>
            <Image src={TargetedJPG} />
            <Info>
              <ContentTitle>Targeted loyalty reinvented</ContentTitle>
              <ContentDescription>
                Our tools allow you to reach the right audience whenever you want for specific campaign promotions. This
                not only improves customer loyalty, but helps diminishes customer acquisition costs.
              </ContentDescription>
            </Info>
          </Content>
        </DropDown>
      </TabsContent>
    </AdaptiveContainer>
  )
}

export default Functionality
